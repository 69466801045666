/* GEAR SLAB */
@font-face {
  font-family: "GearedSlab";
  font-style: normal;
  font-weight: 400;
  src: url('GearedSlab.eot'); /* IE9 Compat Modes */
  src: local('Geared Slab'), local('Geared-Slab'),
       url('GearedSlab.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('GearedSlab.ttf') format('truetype'),
       url('GearedSlab.woff') format('woff'), 
       url('GearedSlab.woff2') format('woff2');
}


@font-face {
  font-family: "GearedSlab-Thin";
  font-style: normal;
  font-weight: 300;
  src: url('GearedSlab-Thin.eot'); /* IE9 Compat Modes */
  src: local('Geared Slab Thin'), local('Geared-Slab-Thin'),
       url('GearedSlab-Thin.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url("GearedSlab-Thin.ttf") format("truetype"),
       url("GearedSlab-Thin.woff") format("woff"), 
       url("GearedSlab-Thin.woff2") format("woff2");
}


@font-face {
  font-family: "GearedSlab-Bold";
  font-style: normal;
  font-weight: 700;
  src: url('GearedSlab-Bold.eot'); /* IE9 Compat Modes */
  src: local('Geared Slab Bold'), local('Geared-Slab-Bold'),
       url('GearedSlab-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url("GearedSlab-Bold.ttf") format("truetype"),
       url("GearedSlab-Bold.woff") format("woff"), 
       url("GearedSlab-Bold.woff2") format("woff2");
}


@font-face {
  font-family: "GearedSlab-ExtraBold";
  font-style: normal;
  font-weight: 800;
  src: url('GearedSlab-Extrabold.eot'); /* IE9 Compat Modes */
  src: local('Geared Slab Extra Bold'), local('Geared-Slab-Extra-Bold'),
       url('GearedSlab-Extrabold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */   
   	   url("GearedSlab-ExtraBold.ttf") format("truetype"),
       url("GearedSlab-ExtraBold.woff") format("woff"), 
       url("GearedSlab-ExtraBold.woff2") format("woff2");
}
